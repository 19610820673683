var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"show-select":"","item-key":"order_id","search":_vm.search,"loading":_vm.loading},on:{"item-selected":_vm.updateAllocatedPortion},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('CustomerPaymentsFilters',{attrs:{"selection":_vm.selectedOrders},on:{"passSearch":function($event){_vm.search = $event},"clearSelection":function($event){return _vm.clearSelection()}}})]},proxy:true},{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewSalesOrder(item)}}},[_vm._v(_vm._s(item.order_number))])]}},{key:"item.order_value",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatAsCurrency("R", item.order_value)))]}},{key:"item.order_amount_paid",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatAsCurrency("R", item.order_amount_paid)))]}},{key:"item.order_amount_due",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatAsCurrency("R", item.order_amount_due)))]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false,"readonly":_vm.amountLeftToAllocate <= 0 && !isSelected,"disabled":_vm.amountLeftToAllocate <= 0 && !isSelected},on:{"input":function($event){return select($event)}}})]}}]),model:{value:(_vm.selectedOrders),callback:function ($$v) {_vm.selectedOrders=$$v},expression:"selectedOrders"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
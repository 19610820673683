<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selectedOrders"
          :headers="headers"
          :items="orders"
          @item-selected="updateAllocatedPortion"
          show-select
          item-key="order_id"
          :search="search"
          :loading="loading"
        >
          <!--Search Bar & Filters-->
          <template v-slot:top>
            <CustomerPaymentsFilters
              @passSearch="search = $event"
              @clearSelection="clearSelection()"
              :selection="selectedOrders"
            />
          </template>
          <!--Remove "Select All" checkbox-->
          <template v-slot:header.data-table-select> </template>
          <!--Click to view Sales Order-->
          <template v-slot:item.order_number="{ item }">
            <a @click="viewSalesOrder(item)">{{ item.order_number }}</a>
          </template>
          <!--Order Total-->
          <template v-slot:item.order_value="{ item }">{{
            formatAsCurrency("R", item.order_value)
          }}</template>
          <!--Order Paid-->
          <template v-slot:item.order_amount_paid="{ item }">{{
            formatAsCurrency("R", item.order_amount_paid)
          }}</template>
          <!--Order Due-->
          <template v-slot:item.order_amount_due="{ item }">{{
            formatAsCurrency("R", item.order_amount_due)
          }}</template>
          <!--Selection checkbox-->
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :ripple="false"
              @input="select($event)"
              :readonly="amountLeftToAllocate <= 0 && !isSelected"
              :disabled="amountLeftToAllocate <= 0 && !isSelected"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../../composables/external";
export default {
  name: "CustomerPaymentsProFormaTable",
  props: ["transaction", "customer", "amountLeftToAllocate"],
  components: {
    CustomerPaymentsFilters: () => import("./CustomerPaymentsFilters"),
  },
  data() {
    return {
      search: "",
      loading: false,
      selectedOrders: [],
      orders: [],
      headers: [
        {
          text: "Sales Order #",
          value: "order_number",
          align: "start",
        },
        { text: "Customer", value: "customer_name" },
        { text: "Date", value: "order_date" },
        { text: "Total", value: "order_value", align: "right" },
        {
          text: "Paid",
          value: "order_amount_paid",
          align: "right",
        },
        {
          text: "Due",
          value: "order_amount_due",
          align: "right",
        },
      ],
    };
  },
  computed: {
    //
    orderAmountAllocted() {
      return this.selectedOrders.reduce(
        (total, item) => total + item.allocatedAmount,
        0
      );
    },
  },
  watch: {
    // Get customer orders when a new customer is selected from dropdown
    customer(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.getOrders();
    },
    selectedOrders(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$emit("updateSelection", newValue);
    },
  },
  created() {
    this.getOrders();
  },
  methods: {
    formatAsCurrency,
    getOrders() {
      const docRef = db
        .collection("sales_orders")
        .where("customer.customer_id", "==", this.customer.customer_id)
        .where("invoice_id", "==", null)
        .where("order_amount_due", "!=", 0);
      docRef.onSnapshot((querySnapshot) => {
        this.loading = true;
        this.orders = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // SuggestedMatches
          this.orders.push({
            ...data,
            customer_name: data.customer.customer_name,
            // Ensures order amounts can be reverted if an order is deselected
            // during the allocation process
            oldAmountPaid: data.order_amount_paid,
            oldAmountDue: data.order_amount_due,
            allocatedAmount: 0,
          });
        });
        this.loading = false;
      });
    },
    //
    updateAllocatedPortion(item) {
      //  When a payment is selected add item amount due to amount paid
      const calculateNewAmounts = () => {
        const calAllocatedAmount = () => {
          const testQuery =
            this.amountLeftToAllocate >= 0 &&
            item.item.order_value <= this.amountLeftToAllocate;

          testQuery
            ? (item.item.allocatedAmount += item.item.oldAmountDue)
            : (item.item.allocatedAmount = this.amountLeftToAllocate);
        };
        calAllocatedAmount();
        item.item.order_amount_paid += item.item.allocatedAmount;
        item.item.order_amount_due -= item.item.allocatedAmount;
      };
      const revertOldAmounts = () => {
        item.item.order_amount_paid = item.item.oldAmountPaid;
        item.item.order_amount_due = item.item.oldAmountDue;
        item.item.allocatedAmount = 0;
      };
      return item.value ? calculateNewAmounts() : revertOldAmounts();
    },
    //  Clear selected orders when customer changes
    clearSelection() {
      this.selectedOrders = [];
      this.$emit("updateSelection", []);
    },
    // Click to view Sales Order in new tab
    viewSalesOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>